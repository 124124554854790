/*
---------------
Main Style File
---------------

⛔️ New styles should very rarely be added here. This stylesheet is mainly
to configure Tailwind, and to apply global styles where it would be impractical
to use Tailwind. Instead, use inline styles or Tailwind custom classes.
*/

/* Inter font family */
@import url("https://rsms.me/inter/inter.css");

/* Import Mapbox styles */
@import url("mapbox.css");

/* Import react-image-crop styles */
@import url("react-image-crop.css");

/* Tailwind styles */
@tailwind base;
@tailwind components;
@tailwind utilities;

/*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
*/
.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

html,
body {
  width: 100%;
  height: 100%;

  /* Add anti-aliasing */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Prevent the page from scrolling beyond the edges */
  overscroll-behavior: none;

  /* Reset the default font weight */
  font-weight: 500;

  /* Set a default font size */
  font-size: 16px;

  user-select: none;
}

/* Adjust the inner div of Radix ScrollArea so we can use flexbox */
.radix-viewport > div {
  display: block !important;
}

.radix-scroll-area.h-full .radix-viewport > div {
  height: 100%;
}

#__next {
  height: 100%;
}

/* We can add the `format-inner-html` class when we want to provide default
styles to user-provided content. */
.format-inner-html ol,
.format-inner-html ul {
  margin: 15px 0 15px 15px;
}

.format-inner-html ol li {
  list-style: decimal;
}

.format-inner-html ul li {
  list-style: disc;
}

.format-inner-html a {
  text-decoration: underline;
}
